import _ from 'lodash';
import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { NewHighlight } from 'src/modules/sections/section-1';
import { DATALAYER_TYPE } from 'src/utils/datalayer/send-datalayer';
import { IAds } from 'src/modules/ads';
import { KEY_ADS_POSITION } from '../ads/dynamicAds';
import { convertDatasets } from 'src/utils/datasets';

const Carousels = dynamic(import('src/components/carousels'));
const CardOverlayFull = dynamic(import('src/components/cards/card-overlay-full'));
const DynamicAds = dynamic(import('src/components/ads/dynamicAds'), { ssr: true });

type Props = {
  data: NewHighlight[];
  unsetBottom: boolean;
  ads?: IAds[];
  highlightText?: any;
};

const SectionHighlight = ({ data = [], unsetBottom = true, ads = [], highlightText = [] }: Props) => {
  const router: any = useRouter();

  return (
    <>
      <SectionWrapper id='section-1' className={`${!unsetBottom ? 'padding-bottom' : ''} highlight`}>
        {!_.isEmpty(data) && (
          <div className='container'>
            <div className='isDesktop'>
              <Carousels slideShow={data?.length >= 3 ? 3 : 1} mobileSlideShow={2} pauseOnHover={false} autoplaySpeed={6000} center={true} dots={true} infinite={data?.length >= 3}>
                {data?.map((item, i) => (
                  <div key={i} className='card-items'>
                    {i === 0 &&
                      <div className='card --title'>
                        <h2>{convertDatasets(highlightText?.[0], 'หัวข้อ1', true) || item?.section?.en}</h2>
                      </div>
                    }
                    {i === 1 &&
                      <div className='card --title'>
                        <h2>{convertDatasets(highlightText?.[1], 'หัวข้อ1', true) || item?.section?.en}</h2>
                      </div>
                    }
                    {i === 2 &&
                      <div className='card --title'>
                        <h2>{convertDatasets(highlightText?.[2], 'หัวข้อ1', true) || item?.section?.en}</h2>
                      </div>
                    }

                    <CardOverlayFull
                      data={item}
                      showTitle={true}
                      showBlurb={false}
                      showByline={false}
                      showTime={false}
                      showCountview={false}
                      heading='highlight'
                      external={false}
                      //! DATALAYER
                      eventDataLayer={{
                        type: DATALAYER_TYPE.TRACK_INDEX,
                        router: router?.pathname,
                        section: 'section-1',
                        data: {
                          title: item?.title,
                          heading: 'hightligth',
                          index: i + 1
                        }
                      }}
                    />
                  </div>
                ))}
              </Carousels>
            </div>
            <div className='isMobile'>
              <Carousels mobileSlideShow={data?.length || 2} pauseOnHover={false} autoplaySpeed={6000} dots={true} infinite={data?.length >= 3}>
                {data?.map((item, i) => (
                  <div key={i} className='card-items'>
                    {i === 0 &&
                      <div className='card --title'>
                        <h2>{convertDatasets(highlightText?.[0], 'หัวข้อ1', true) || item?.section?.en}</h2>
                      </div>
                    }
                    {i === 1 &&
                      <div className='card --title'>
                        <h2>{convertDatasets(highlightText?.[1], 'หัวข้อ1', true) || item?.section?.en}</h2>
                      </div>
                    }
                    {i === 2 &&
                      <div className='card --title'>
                        <h2>{convertDatasets(highlightText?.[2], 'หัวข้อ1', true) || item?.section?.en}</h2>
                      </div>
                    }
                    <CardOverlayFull
                      data={item}
                      showTitle={true}
                      showBlurb={false}
                      showByline={false}
                      showTime={false}
                      showCountview={false}
                      heading='highlight'
                      external={false}
                      //! DATALAYER
                      eventDataLayer={{
                        type: DATALAYER_TYPE.TRACK_INDEX,
                        router: router?.pathname,
                        section: 'section-1',
                        data: {
                          title: item?.title,
                          heading: 'hightligth',
                          carousel: i + 1
                        }
                      }}
                    />
                  </div>
                ))}
              </Carousels>
            </div>
            {/* Ads-PPN BILLBOARD */}
            {!_.isEmpty(ads) && <DynamicAds position={KEY_ADS_POSITION.BILLBOARD} data={ads} />}
            {unsetBottom && <div className='border-bottom' />}
          </div>
        )}
      </SectionWrapper>
    </>
  );
};

const SectionWrapper = styled.section`
  margin-top: -15px;
  & > * div {
    transition: all 0s ease-in-out;
  }
  overflow: hidden;
  .slick-dots {
    bottom: 0;
    position: relative;
    @media (min-width: 769px) {
      position: absolute;
      display: none !important;
    }
  }
  &.padding-bottom {
    padding-bottom: 30px;
  }
  .slick-slider {
    margin: 0 -15px;
  }
  .isDesktop {
    display: block;
    border-top: 2px solid #b3b3b36e;
    padding-top: 15px;
    margin-top: 60px;
    @media (max-width: 1024px) {
      padding-top: 0;
      margin-top: 0;
      border-top: 0px solid transparent;
    }
    @media (max-width: 480px) {
      display: none;
    }
  }
  .isMobile {
    display: none;
    @media (max-width: 480px) {
      display: block;
    }
  }
  .card-items {
    padding-left: 5.5px;
    padding-right: 5.5px;
    .card {
      &.--title {
        text-align: center;
        h2 {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 15px;
          text-transform: uppercase ;
        }
      }
    }
  }

  .container {
    padding: 0 0px;
    @media (max-width: 1024px) {
      padding: 0;
    }
  }

  .border-bottom {
    margin-top: 30px;
    border-bottom: 1px solid #80808042;
  }
`;

export default SectionHighlight;
